import { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { ReactComponent as DeleteAcc } from "../../assests/deleteacc.svg";

export default function DeleteAccount(params) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div style={{ marginTop: "4rem" }}>
        <div
          style={{
            background:
              "linear-gradient(226.56deg, #D94D0D 52.07%, rgba(217, 77, 13, 0) 111.88%)",
            padding: "5rem 0rem",
          }}
        >
          <div className="d-flex flex-md-row flex-column justify-content-between align-items-center col-md-10 mx-auto">
            <h1 className="text-center white fw-bold">
              Steps to delete your <br /> bahcode account
            </h1>
            <h1 className="text-center white fw-bold">
              <DeleteAcc
                style={{ width: window.innerWidth < 576 && "395px" }}
              />
            </h1>
          </div>
        </div>
        <div className="col-10 mx-auto my-5">
          <div>
            <h5 className="fw-bold black">1. Open the app</h5>
            <h6 className="black my-3">Launch the app on your device.</h6>
            <h5 className="fw-bold black">2. Navigate to Account Settings</h5>
            <h6 className="black my-3">
              Locate and tap on the " Settings" section within the app. This is
              typically found in the app's menu or navigation drawer.
            </h6>
            <h5 className="fw-bold black">3. Find Delete Account Option</h5>
            <h6 className="black my-3">
              Click on "Account Information", look for the Delete Account"
              button.
            </h6>
            <h5 className="fw-bold black">4. Tap Delete Account</h5>
            <h6 className="black my-3">
              Tap on the "Delete Account" option to initiate the account
              deletion process.
            </h6>
            <h5 className="fw-bold black">5. Read Confirmation Message</h5>
            <h6 className="black my-3">
              A confirmation screen will appear, providing information about the
              consequences of deleting your account. Take a moment to read the
              message carefully.
            </h6>
            <h5 className="fw-bold black">
              6. Provide Additional Verification
            </h5>
            <h6 className="black my-3">
              Depending on the account requirements, you will be asked to
              provide either a Login Pin or Transaction Pin
            </h6>
            <h5 className="fw-bold black">7. Confirm Deletion</h5>
            <h6 className="black my-3">
              Once you have read the confirmation message and completed any
              necessary verification steps, tap on the "Confirm" button to
              proceed with the account deletion.
            </h6>
            <h5 className="fw-bold black">8. Wait for Deletion Process</h5>
            <h6 className="black my-3">
              After confirming the deletion, please wait while the app processes
              your request. This may take a few moments.
            </h6>
            <h5 className="fw-bold black">9. Account Deletion Successful</h5>
            <h6 className="black my-3">
              Once the account deletion process is complete, you will receive a
              confirmation message stating that your account has been
              successfully deleted.
            </h6>
            <h5 className="fw-bold black">10. Verify Account Deletion</h5>
            <h6 className="black my-3">
              If you wish, you can verify the deletion by attempting to log in
              to the app using your previously registered credentials. You
              should no longer be able to access your account.
            </h6>
          </div>
          <div className="my-5">
            <h2 className="orange fw-bolder text-center mb-5">
              When you delete your account, the following will happen
            </h2>
            <div className="my-3">
              <h3 className="black fw-bolder">
                Information that will be deleted
              </h3>
              <h6 className="black mt-3">
                Your account profile information, including your username, email
                address, and any associated personal details.{" "}
              </h6>
              <h6 className="black">
                Any data you have provided within the app, such as preferences,
                settings, and saved content.
              </h6>
              <h6 className="black">
                Please note that once your account is deleted, this data cannot
                be recovered.
              </h6>
              <h3 className="black fw-bolder my-3">
                Information that will be kept
              </h3>
              <h6 className="black mt-3">
                Anonymized or aggregated data that does not personally identify
                you may be retained for statistical or analytical purposes. This
                data is used to improve our app's performance and user
                experience but does not contain any personally identifiable
                information.
              </h6>
              <h6 className="black">
                It's important to remember that any data shared with other users
                or stored externally outside the app may still be accessible to
                them even after your account is deleted.
              </h6>
              <h6 className="black">
                If you have any concerns or questions regarding the deletion
                process or the data involved, please contact our support team at
                support@bahcode.com.
              </h6>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
