import { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

export default function Policy(params) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div style={{ marginTop: "4rem" }}>
        <div
          style={{
            background:
              "linear-gradient(226.56deg, #D94D0D 52.07%, rgba(217, 77, 13, 0) 111.88%)",
            padding: "5rem 0rem",
          }}
        >
          <h1 className="text-center white fw-bold">Privacy & Policy</h1>
          <div style={{ textAlign: "justify" }}>
            <p className="text-center white col-sm-6 col-lg-5 col-xl-4 col-10 mx-auto">
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You.
              <br />
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy. This Privacy
              Policy has been created with the help of the Privacy Policy
              Generator.
            </p>
          </div>
        </div>
        <div className="col-10 mx-auto my-5">
          <div className="my-5">
            <h2 className="orange fw-bolder text-center mb-5">
              Interpretation and Definitions
            </h2>
            <div className="my-3">
              <h3 className="orange fw-bolder">Interpretation</h3>
              <h6 className="black mt-3">
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </h6>
              <h3 className="orange fw-bolder my-3">Definitions</h3>
              <h6 className="black mt-3">
                For the purposes of this Privacy Policy:
                <br />
                <br />
                <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                  Account{" "}
                </span>
                means a unique account created for You to access our Service or
                parts of our Service.
                <br />
                <br />
                <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                  Affiliate
                </span>{" "}
                means an entity that controls, is controlled by or is under
                common control with a party, where "control" means ownership of
                50% or more of the shares, equity interest or other securities
                entitled to vote for election of directors or other managing
                authority.
                <br />
                <br />
                <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                  Application
                </span>{" "}
                refers to Bahcode, the software program provided by the Company.
                <br />
                <br />
                <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                  Company{" "}
                </span>
                (referred to as either "the Company", "We", "Us" or "Our" in
                this Agreement) refers to Tervan Analytics Nigeria Limited, Plot
                16, Block G, Eden Gardens Estate, Lekki Ajah, Lagos, Nigeria.
                <br />
                <br />
                <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                  Country{" "}
                </span>
                refers to: Nigeria
                <br />
                <br />
                <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                  Device{" "}
                </span>
                means any device that can access the Service such as a computer,
                a cellphone or a digital tablet.
                <br />
                <br />
                <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                  Personal{" "}
                </span>
                Data is any information that relates to an identified or
                identifiable individual.
                <br />
                <br />
                <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                  Service{" "}
                </span>
                refers to the Application.
                <br />
                <br />
                <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                  Service Provider{" "}
                </span>
                means any natural or legal person who processes the data on
                behalf of the Company. It refers to third-party companies or
                individuals employed by the Company to facilitate the Service,
                to provide the Service on behalf of the Company, to perform
                services related to the Service or to assist the Company in
                analyzing how the Service is used.
                <br />
                <br />
                <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                  Usage Data{" "}
                </span>
                refers to data collected automatically, either generated by the
                use of the Service or from the Service infrastructure itself
                (for example, the duration of a page visit).
                <br />
                <br />
                <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                  You{" "}
                </span>
                means the individual accessing or using the Service, or the
                company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </h6>
            </div>
            <div className="my-3">
              <h2 className="orange fw-bolder text-center mb-5">
                Collecting and Using Your Personal Data
              </h2>
              <div className="my-3">
                <h4 className="orange fw-bolder">Types of Data Collected</h4>
                <h5 className="orange fw-bolder mt-3">Personal Data</h5>
                <h6 className="black mt-2">
                  While using Our Service, We may ask You to provide Us with
                  certain personally identifiable information that can be used
                  to contact or identify You. Personally identifiable
                  information may include, but is not limited to: Email address,
                  First name and last name, Phone number, Address, State,
                  Province, ZIP/Postal code, City, BVN, Bank Account Number,
                  Usage Data.
                </h6>
                <h5 className="orange fw-bolder mt-3">Usage Data</h5>
                <h6 className="black mt-2">
                  Usage Data is collected automatically when using the Service.
                  <br />
                  <br />
                  Usage Data may include information such as Your Device's
                  Internet Protocol address (e.g. IP address), browser type,
                  browser version, the pages of our Service that You visit, the
                  time and date of Your visit, the time spent on those pages,
                  unique device identifiers and other diagnostic data.
                  <br />
                  <br />
                  When You access the Service by or through a mobile device, We
                  may collect certain information automatically, including, but
                  not limited to, the type of mobile device You use, Your mobile
                  device unique ID, the IP address of Your mobile device, Your
                  mobile operating system, the type of mobile Internet browser
                  You use, unique device identifiers and other diagnostic data.
                  <br />
                  <br />
                  We may also collect information that Your browser sends
                  whenever You visit our Service or when You access the Service
                  by or through a mobile device.
                </h6>
                <h5 className="orange fw-bolder mt-3">
                  Information Collected while Using the Application
                </h5>
                <h6 className="black mt-2">
                  While using Our Application, in order to provide features of
                  Our Application, We may collect, with Your prior permission:
                  <br />
                  <br />
                  <ul>
                    <li>
                      {" "}
                      • &nbsp; &nbsp;Information from your Device's phone book
                      (contacts list)
                    </li>
                    <li>
                      • &nbsp;&nbsp;Pictures and other information from your
                      Device's camera and photo library
                    </li>
                  </ul>
                  We use this information to provide features of Our Service, to
                  improve and customize Our Service. The information may be
                  uploaded to the Company's servers and/or a Service Provider's
                  server or it may be simply stored on Your device.
                  <br />
                  <br />
                  You can enable or disable access to this information at any
                  time, through Your Device settings.
                </h6>
                <h5 className="orange fw-bolder my-3">
                  Use of Your Personal Data
                </h5>
                <h6 className="black mt-3">
                  The Company may use Personal Data for the following purposes:
                  <br />
                  <br />
                  <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                    To provide and maintain our Service
                  </span>
                  , including to monitor the usage of our Service.
                  <br />
                  <br />
                  <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                    To manage Your Account:
                  </span>{" "}
                  to manage Your registration as a user of the Service. The
                  Personal Data You provide can give You access to different
                  functionalities of the Service that are available to You as a
                  registered user.
                  <br />
                  <br />
                  <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                    For the performance of a contract:
                  </span>{" "}
                  the development, compliance and undertaking of the purchase
                  contract for the products, items or services You have
                  purchased or of any other contract with Us through the
                  Service.
                  <br />
                  <br />
                  <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                    To contact You:{" "}
                  </span>
                  To contact You by email, telephone calls, SMS, or other
                  equivalent forms of electronic communication, such as a mobile
                  application's push notifications regarding updates or
                  informative communications related to the functionalities,
                  products or contracted services, including the security
                  updates, when necessary or reasonable for their
                  implementation.
                  <br />
                  <br />
                  <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                    To provide You{" "}
                  </span>
                  with news, special offers and general information about other
                  goods, services and events which we offer that are similar to
                  those that you have already purchased or enquired about unless
                  You have opted not to receive such information.
                  <br />
                  <br />
                  <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                    To manage Your requests:{" "}
                  </span>
                  To attend and manage Your requests to Us.
                  <br />
                  <br />
                  <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                    For business transfers:{" "}
                  </span>
                  We may use Your information to evaluate or conduct a merger,
                  divestiture, restructuring, reorganization, dissolution, or
                  other sale or transfer of some or all of Our assets, whether
                  as a going concern or as part of bankruptcy, liquidation, or
                  similar proceeding, in which Personal Data held by Us about
                  our Service users is among the assets transferred.
                  <br />
                  <br />
                  <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                    For other purposes:{" "}
                  </span>
                  We may use Your information for other purposes, such as data
                  analysis, identifying usage trends, determining the
                  effectiveness of our promotional campaigns and to evaluate and
                  improve our Service, products, services, marketing and your
                  experience.
                  <br />
                  <br />
                  We may share Your personal information in the following
                  situations:
                  <br />
                  <br />
                  <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                    • &nbsp;&nbsp; With Service Providers:{" "}
                  </span>
                  We may share Your personal information with Service Providers
                  to monitor and analyze the use of our Service, to contact You.
                  <br />
                  <br />
                  <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                    • &nbsp;&nbsp; For business transfers:{" "}
                  </span>
                  We may share or transfer Your personal information in
                  connection with, or during negotiations of, any merger, sale
                  of Company assets, financing, or acquisition of all or a
                  portion of Our business to another company.
                  <br />
                  <br />
                  <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                    • &nbsp;&nbsp; With Affiliates: :{" "}
                  </span>
                  We may share Your information with Our affiliates, in which
                  case we will require those affiliates to honor this Privacy
                  Policy. Affiliates include Our parent company and any other
                  subsidiaries, joint venture partners or other companies that
                  We control or that are under common control with Us.
                  <br />
                  <br />
                  <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                    • &nbsp;&nbsp; With business partners:{" "}
                  </span>
                  We may share Your information with Our business partners to
                  offer You certain products, services or promotions.
                  <br />
                  <br />
                  <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                    • &nbsp;&nbsp; With other users:{" "}
                  </span>
                  when You share personal information or otherwise interact in
                  the public areas with other users, such information may be
                  viewed by all users and may be publicly distributed outside.
                  <br />
                  <br />
                  <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                    • &nbsp;&nbsp; With Your consent:{" "}
                  </span>
                  We may disclose Your personal information for any other
                  purpose with Your consent.
                </h6>
                <h5 className="orange fw-bolder mt-3">
                  Retention of Your Personal Data
                </h5>
                <h6 className="black mt-2">
                  The Company will retain Your Personal Data only for as long as
                  is necessary for the purposes set out in this Privacy Policy.
                  We will retain and use Your Personal Data to the extent
                  necessary to comply with our legal obligations (for example,
                  if we are required to retain your data to comply with
                  applicable laws), resolve disputes, and enforce our legal
                  agreements and policies.
                  <br />
                  <br />
                  The Company will also retain Usage Data for internal analysis
                  purposes. Usage Data is generally retained for a shorter
                  period of time, except when this data is used to strengthen
                  the security or to improve the functionality of Our Service,
                  or We are legally obligated to retain this data for longer
                  time periods.
                </h6>
                <h5 className="orange fw-bolder mt-3">
                  Transfer of Your Personal Data
                </h5>
                <h6 className="black mt-2">
                  Your information, including Personal Data, is processed at the
                  Company's operating offices and in any other places where the
                  parties involved in the processing are located. It means that
                  this information may be transferred to — and maintained on —
                  computers located outside of Your state, province, country or
                  other governmental jurisdiction where the data protection laws
                  may differ than those from Your jurisdiction.
                  <br />
                  <br />
                  Your consent to this Privacy Policy followed by Your
                  submission of such information represents Your agreement to
                  that transfer.
                  <br />
                  <br />
                  The Company will take all steps reasonably necessary to ensure
                  that Your data is treated securely and in accordance with this
                  Privacy Policy and no transfer of Your Personal Data will take
                  place to an organization or a country unless there are
                  adequate controls in place including the security of Your data
                  and other personal information.
                </h6>
                <h5 className="orange fw-bolder mt-3">
                  Delete Your Personal Data
                </h5>
                <h6 className="black mt-2">
                  You have the right to delete or request that We assist in
                  deleting the Personal Data that We have collected about You.
                  <br />
                  <br />
                  Our Service may give You the ability to delete certain
                  information about You from within the Service.
                  <br />
                  <br />
                  You may update, amend, or delete Your information at any time
                  by signing in to Your Account, if you have one, and visiting
                  the account settings section that allows you to manage Your
                  personal information. You may also contact Us to request
                  access to, correct, or delete any personal information that
                  You have provided to Us.
                  <br />
                  <br />
                  Please note, however, that We may need to retain certain
                  information when we have a legal obligation or lawful basis to
                  do so.
                </h6>
                <h5 className="orange fw-bolder mt-3">
                  Disclosure of Your Personal Data
                </h5>
                <h6 className="orange fw-bolder mt-2">Business Transactions</h6>
                <h6 className="black mt-2">
                  If the Company is involved in a merger, acquisition or asset
                  sale, Your Personal Data may be transferred. We will provide
                  notice before Your Personal Data is transferred and becomes
                  subject to a different Privacy Policy.
                </h6>
                <h6 className="orange fw-bolder mt-2">Law enforcement</h6>
                <h6 className="black mt-2">
                  Under certain circumstances, the Company may be required to
                  disclose Your Personal Data if required to do so by law or in
                  response to valid requests by public authorities (e.g. a court
                  or a government agency).
                </h6>
                <h6 className="orange fw-bolder mt-2">
                  Other legal requirements
                </h6>
                <h6 className="black mt-2">
                  The Company may disclose Your Personal Data in the good faith
                  belief that such action is necessary to:
                  <br />
                  <br />
                  <ul>
                    <li>• &nbsp;&nbsp;Comply with a legal obligation</li>
                    <li>
                      • &nbsp;&nbsp;Protect and defend the rights or property of
                      the Company
                    </li>
                    <li>
                      • &nbsp;&nbsp;Prevent or investigate possible wrongdoing
                      in connection with the Service
                    </li>
                    <li>
                      • &nbsp;&nbsp;Protect the personal safety of Users of the
                      Service or the public
                    </li>
                    <li>• &nbsp;&nbsp;Protect against legal liability</li>
                  </ul>
                </h6>
                <h5 className="orange fw-bolder mt-3">
                  Security of Your Personal Data
                </h5>

                <h6 className="black mt-2">
                  The security of Your Personal Data is important to Us, but
                  remember that no method of transmission over the Internet, or
                  method of electronic storage is 100% secure. While We strive
                  to use commercially acceptable means to protect Your Personal
                  Data, We cannot guarantee its absolute security.
                </h6>
                <br />
                <h2 className="orange fw-bolder text-center mb-4">
                  Children's Privacy
                </h2>
                <h6 className="black">
                  Our Service does not address anyone under the age of 18. We do
                  not knowingly collect personally identifiable information from
                  anyone under the age of 18. If You are a parent or guardian
                  and You are aware that Your child has provided Us with
                  Personal Data, please contact Us. If We become aware that We
                  have collected Personal Data from anyone under the age of 18
                  without verification of parental consent, We take steps to
                  remove that information from Our servers.
                  <br />
                  <br />
                  If We need to rely on consent as a legal basis for processing
                  Your information and Your country requires consent from a
                  parent, We may require Your parent's consent before We collect
                  and use that information.
                </h6>
                <h2 className="orange fw-bolder text-center mb-4">
                  Links to Other Websites
                </h2>
                <h6 className="black">
                  Our Service may contain links to other websites that are not
                  operated by Us. If You click on a third party link, You will
                  be directed to that third party's site. We strongly advise You
                  to review the Privacy Policy of every site You visit.
                  <br />
                  <br />
                  We have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third party
                  sites or services.
                </h6>
                <br />
                <h2 className="orange fw-bolder text-center mb-4">
                  Integration with Smartech SDK:
                </h2>
                <h6 className="black">
                  Our app integrates with the Smartech SDK provided by Netcore
                  Solutions Pvt. Ltd. ("Smartech") to enable certain
                  functionalities and provide personalized experiences.
                  <br />
                  <br />
                  Smartech adheres to industry-leading security practices and
                  safeguards user data according to their privacy policy. We
                  encourage you to review Smartech's privacy policy to
                  understand how they protect your information:
                  https://netcorecloud.com/privacy-policy/
                </h6>
                <br />
                <h2 className="orange fw-bolder text-center mb-4">
                  Changes to this Privacy Policy
                </h2>
                <h6 className="black">
                  We may update Our Privacy Policy from time to time. We will
                  notify You of any changes by posting the new Privacy Policy on
                  this page.
                  <br />
                  <br />
                  We will let You know via email and/or a prominent notice on
                  Our Service, prior to the change becoming effective and update
                  the "Last updated" date at the top of this Privacy Policy.
                  <br />
                  <br />
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </h6>
                <br />
                <h2 className="orange fw-bolder text-center mb-4">
                  Contact Us
                </h2>
                <h6 className="black">
                  If you have any questions about this Privacy Policy, You can
                  contact us:
                  <br />
                  <br />
                  By email: info@bahcode.com
                  <br />
                  <br />
                  By visiting this page on our website:
                  https://bahcodeapp.com/privacypolicy
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
