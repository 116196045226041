import { useNavigate } from "react-router-dom";
import { ReactComponent as Phone } from "../../assests/call.svg";
import { ReactComponent as Info } from "../../assests/info.svg";

export default function Footer(params) {
  const navigate = useNavigate();
  const d = new Date();
  let year = d.getFullYear();

  return (
    <>
      <div style={{ background: "rgba(0, 0, 0, 0.81)", padding: "1rem" }}>
        <h4 className="orange fw-bold text-center">Bahcode</h4>
        <h6 className="white fw-bold text-center">
          Discover the power of seamless business management
        </h6>
        <div className="col-11 mx-auto">
          <h6 className="white fw-bold">Quick Links</h6>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <small
                className="my-2 white cursor"
                onClick={() => navigate("/home")}
              >
                Home
              </small>
              <small
                className="my-2 white cursor"
                onClick={() => navigate("/features")}
              >
                Features
              </small>
              <small
                className="my-2 white cursor"
                onClick={() => navigate("/pricing")}
              >
                Pricing
              </small>
              <small
                className="my-2 white cursor"
                onClick={() => navigate("/help")}
              >
                Help & Support
              </small>
              <small
                className="my-2 white cursor"
                onClick={() => navigate("/privacypolicy")}
              >
                Privacy Policy
              </small>
              <small
                className="my-2 white cursor"
                onClick={() => navigate("/deleteaccount")}
              >
                Delete Account
              </small>
            </div>
            <div className="d-sm-flex justify-content-end">
              <div className="mt-5">
                <h4 className="fw-bold white">Contact</h4>
                <p className="white mb-2 pb-1">
                  <Info className="me-3" />
                  info@bahcode.com
                </p>
                <small className="white">
                  <Phone className="me-3" />
                  +2348103273700
                </small>
              </div>
            </div>
          </div>

          <hr style={{ color: "white", background: "white", height: "3px" }} />
          <div className="d-sm-flex align-items-center justify-content-between">
            <small className="white fw-bold">
              &#169; {year} Bahcode&#174; Inc.
            </small>
            <div className="d-flex align-items-center">
              <small className="white me-4 fw-bold">Terms & Conditions</small>
              <small className="white fw-bold">Privacy Policy</small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
